
/**
 * `kioskApiPages`.
 */

const kioskApiPages = {
  kioskHome: 'kiosk-homepage'
};

/**
 * Export `fr` api page routes.
 */

export const fr = {
  ...kioskApiPages,
  aboutUs: 'qui-sommes-nous',
  aboutUsSgiPolicy: 'politique-du-systeme-de-management-integre',
  careers: 'recrutement',
  collection: null,
  collections: 'collections',
  contacts: 'contacts',
  cookiesPolicy: 'politique-cookies',
  downloads: 'telechargements',
  environment: 'environnement',
  faq: null,
  faqs: 'faq',
  fundedProjects: null,
  highlights: 'actualites',
  history: 'histoire',
  home: 'page-d-accueil',
  innovation: 'innovation',
  inspiration: 'inspiration',
  inspirationDetails: 'detail-d-inspiration',
  media: 'medias',
  portfolio: 'portefeuille',
  portfolioDetails: 'detail-du-portefeuille',
  privacyPolicy: 'politique-confidentialite',
  productContact: 'produit-contactez-nous',
  productDetails: null,
  recommend: 'conseiller',
  recommendResults: null,
  reportingChannel: 'canal-de-signalement',
  socialResponsability: 'responsabilite-sociale',
  socialResponsabilityAspects: 'aspects-de-responsabilite-sociale',
  socialResponsabilityCollaboratingStandards: 'normes-de-collaboration-en-matiere-de-responsabilite-sociale',
  socialResponsabilitySuppliers: 'responsabilite-sociale-fournisseurs',
  storytiling: 'storytiling',
  storytilingDetails: 'detail-storytiling',
  termsConditions: 'termes-et-conditions',
  toolsBimObjects: 'bim',
  toolsEnvironmentSimulator: 'simulateur-environnement',
  toolsProductComparator: 'comparateur',
  toolsQuantitiesCalculator: 'calculateur',
  whereToBuy: 'ou-acheter',
  whereToBuyResults: 'resultats-ou-acheter'
};
